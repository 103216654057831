<template>
  <div>
    <v-card-title class="pt-0 text-h5 font-weight-medium">
      {{ region }}
    </v-card-title>
    <v-card-text class="py-0 font-weight-bold">
      <v-row
        v-for="countryCount in topNCountries"
        :key="`${countryCount.word}-${countryCount.count}`"
        class="text-subtitle-1"
      >
        <v-col
          cols="12"
          md="7"
          class="py-2 font-weight-medium black--text"
        >
          {{ countryName(countryCount.word) }}
        </v-col>
        <v-col cols="12" md="5" class="py-2 text-right">
          <span class="black--text">
            {{ countryCount.count }}
          </span>
          ({{ percentage(countryCount.count) }}%)
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { getName } from 'country-list';

export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    region: {
      required: false,
      default: '',
      type: String
    },
    limit: {
      required: false,
      type: Number,
      default: 5
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    // Recalculate totalCount every time questionStat changes.
    totalCount() {
      return Object.values(this.questionStat.wordSelections).reduce(
        (total, wordSelection) => total + wordSelection.count,
        0
      );
    },
    // Directly use the prop so it stays reactive.
    topNCountries() {
      return [...this.questionStat.wordSelections] // Clone the array
        .sort((a, b) => b.count - a.count) // Sort descending
        .slice(0, this.limit);
    }
  },
  methods: {
    countryName(word) {
      return getName(word.languageMap[this.displayLanguage]);
    },
    percentage(count) {
      return count === 0
        ? 0
        : Math.round((count / this.totalCount) * 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.row:nth-child(odd) {
  background-color: var(--v-primary-transparent-base);
}
</style>
