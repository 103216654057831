<template>
  <div class="d-flex flex-column">
    <div
      v-for="(value, index) in questionStat.values"
      :key="index"
      :class="`py-${paddingY}`"
    >
      <v-row>
        <localised-text
          v-if="value.label && value.label[displayLanguage]"
          :language="displayLanguage"
          :string="value.label"
          class="pl-12 text flex-item"
          :style="{ fontSize: `${sliderTitleSize}px` }"
        />
      </v-row>
      <slider-svg
        class="mx-12"
        :class="`mt-${marginTop}`"
        :theme="theme"
        :width="responsiveWidth"
        :height="sliderHeight"
        :tick-height="sliderTickHeight"
        :slider-value="value.average"
        :middle-tick="!!value.middleWord"
        :total-sliders="questionStat.values.length"
      />
      <div class="d-flex justify-space-between">
        <div
          v-for="(sliderPoint, _index) in sliderPoints(index)"
          :key="_index"
          class="text-body-1 text-center font-weight-bold label"
          :class="`mt-${marginTop / 1.5}`"
        >
          <localised-text
            :language="displayLanguage"
            :string="sliderPoint.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText';
import SliderSvg from '@/components/Visualisations/SliderSvg';
import colors from 'vuetify/lib/util/colors';

export default {
  components: {
    LocalisedText,
    SliderSvg
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    numberOfSliders() {
      return this.questionStat.values.length;
    },
    responsiveWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200;
        case 'sm':
          return 200;
        case 'md':
          return 300;
        case 'lg':
          return 400;
        case 'xl':
          return 600;
        default:
          return 800;
      }
    },
    sliderHeight() {
      return 20 / this.numberOfSliders;
    },
    sliderTickHeight() {
      return 40 / this.numberOfSliders;
    },
    marginTop() {
      return this.numberOfSliders <= 3 ? 12 : 10;
    },
    paddingY() {
      return Math.ceil(12 / this.numberOfSliders);
    },
    sliderTitleSize() {
      return this.numberOfSliders <= 3 ? 24 : 20;
    },
    theme() {
      return {
        slider: {
          barColour: colors.grey.base,
          tickColour: colors.grey.base,
          gradient: {
            start: this.$vuetify.theme.defaults.light.primary,
            end: this.$vuetify.theme.defaults.light.primary
          }
        }
      };
    }
  },
  methods: {
    sliderPoints(index) {
      const options = [
        {
          position: 'left',
          text: this.questionStat.values[index].leftWord
        },
        {
          position: 'right',
          text: this.questionStat.values[index].rightWord
        }
      ];

      if (
        this.questionStat.values[index].middleWord &&
        this.questionStat.values[index].middleWord.languageMap[
          this.displayLanguage
        ]
      ) {
        options.splice(1, 0, {
          position: 'middle',
          text: this.questionStat.values[index].middleWord
        });
      }

      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.text {
  fill: map-get($shades, 'white');
  font-weight: bold;
}
.label {
  min-width: 100px;
}
</style>
