import { render, staticRenderFns } from "./LanguageAvatar.vue?vue&type=template&id=6e334dd6&scoped=true&"
import script from "./LanguageAvatar.vue?vue&type=script&lang=js&"
export * from "./LanguageAvatar.vue?vue&type=script&lang=js&"
import style0 from "./LanguageAvatar.vue?vue&type=style&index=0&id=6e334dd6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e334dd6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e334dd6')) {
      api.createRecord('6e334dd6', component.options)
    } else {
      api.reload('6e334dd6', component.options)
    }
    module.hot.accept("./LanguageAvatar.vue?vue&type=template&id=6e334dd6&scoped=true&", function () {
      api.rerender('6e334dd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/LanguageAvatar.vue"
export default component.exports