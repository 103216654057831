var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.questionStat.values, function(value, index) {
      return _c(
        "div",
        { key: index, staticClass: "py-2" },
        [
          value.label && value.label[_vm.displayLanguage]
            ? _c("localised-text", {
                staticClass: "pl-12 text",
                attrs: { language: _vm.displayLanguage, string: value.label }
              })
            : _vm._e(),
          _c("slider-svg", {
            staticClass: "mt-4 mx-12",
            attrs: {
              theme: _vm.theme,
              width: _vm.responsiveWidth,
              "slider-value": value.average,
              "middle-tick": !!value.middleWord
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            _vm._l(_vm.sliderPoints(index), function(sliderPoint, _index) {
              return _c(
                "div",
                {
                  key: _index,
                  staticClass: "text-body-1 text-center font-weight-bold label"
                },
                [
                  _c("localised-text", {
                    attrs: {
                      language: _vm.displayLanguage,
                      string: sliderPoint.text
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }