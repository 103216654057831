import { render, staticRenderFns } from "./MemberForm.vue?vue&type=template&id=12ebbcc4&"
import script from "./MemberForm.vue?vue&type=script&lang=js&"
export * from "./MemberForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardActions,VCardText,VForm,VSelect,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12ebbcc4')) {
      api.createRecord('12ebbcc4', component.options)
    } else {
      api.reload('12ebbcc4', component.options)
    }
    module.hot.accept("./MemberForm.vue?vue&type=template&id=12ebbcc4&", function () {
      api.rerender('12ebbcc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Organisation/Edit/MemberForm.vue"
export default component.exports