import { render, staticRenderFns } from "./MultipleChoiceConfig.vue?vue&type=template&id=05d893ec&"
import script from "./MultipleChoiceConfig.vue?vue&type=script&lang=js&"
export * from "./MultipleChoiceConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCol,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05d893ec')) {
      api.createRecord('05d893ec', component.options)
    } else {
      api.reload('05d893ec', component.options)
    }
    module.hot.accept("./MultipleChoiceConfig.vue?vue&type=template&id=05d893ec&", function () {
      api.rerender('05d893ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Survey/Edit/QuestionConfig/MultipleChoiceConfig.vue"
export default component.exports