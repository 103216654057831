<template>
  <div>
    <v-row>
      <v-col cols="8">
        <h6></h6>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'YesNoConfig',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      yesFollowUpId: this.value.yesFollowUpId,
      noFollowUpId: this.value.noFollowUpId
    };
  }
};
</script>
