import { render, staticRenderFns } from "./PublicDisplayPage.vue?vue&type=template&id=232fbe54&scoped=true&"
import script from "./PublicDisplayPage.vue?vue&type=script&lang=js&"
export * from "./PublicDisplayPage.vue?vue&type=script&lang=js&"
import style0 from "./PublicDisplayPage.vue?vue&type=style&index=0&id=232fbe54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232fbe54",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VFadeTransition,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('232fbe54')) {
      api.createRecord('232fbe54', component.options)
    } else {
      api.reload('232fbe54', component.options)
    }
    module.hot.accept("./PublicDisplayPage.vue?vue&type=template&id=232fbe54&scoped=true&", function () {
      api.rerender('232fbe54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PublicDisplayPage.vue"
export default component.exports