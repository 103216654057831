import { render, staticRenderFns } from "./OrganisationListItemLoader.vue?vue&type=template&id=f2a28216&"
import script from "./OrganisationListItemLoader.vue?vue&type=script&lang=js&"
export * from "./OrganisationListItemLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f2a28216')) {
      api.createRecord('f2a28216', component.options)
    } else {
      api.reload('f2a28216', component.options)
    }
    module.hot.accept("./OrganisationListItemLoader.vue?vue&type=template&id=f2a28216&", function () {
      api.rerender('f2a28216', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Organisation/Loader/OrganisationListItemLoader.vue"
export default component.exports