import { render, staticRenderFns } from "./SurveyCard.vue?vue&type=template&id=a695bbd4&scoped=true&"
import script from "./SurveyCard.vue?vue&type=script&lang=js&"
export * from "./SurveyCard.vue?vue&type=script&lang=js&"
import style0 from "./SurveyCard.vue?vue&type=style&index=0&id=a695bbd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a695bbd4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VCol,VContainer,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a695bbd4')) {
      api.createRecord('a695bbd4', component.options)
    } else {
      api.reload('a695bbd4', component.options)
    }
    module.hot.accept("./SurveyCard.vue?vue&type=template&id=a695bbd4&scoped=true&", function () {
      api.rerender('a695bbd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Survey/List/SurveyCard.vue"
export default component.exports